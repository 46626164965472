import React from 'react';
import Layout from '../Theme/layout';
import SEO from '../components/seo';
import HeaderSlider from '../components/pages/article/SliderHeader/HeaderSlider';
import Article from '../components/pages/article/Article/Article';
import { graphql } from 'gatsby';

export const query = graphql`
  query singleArticle($id: String!) {
  datoCmsArticle(id: {eq: $id}) {
    article {
      ... on DatoCmsParagraph {
        paragraph
      }
      ... on DatoCmsTitle {
        titleNode {
          id
        }
        title
      }
      ... on DatoCmsOrnamentTitle {
        ornamentTitle
      }
      ... on DatoCmsOrnamentParagraph {
        ornamentParagraph
      }
    }
    title
    id
    miniature {
      alt
        fluid(maxWidth: 900) {
            ...GatsbyDatoCmsFluid_tracedSVG
        }
    }
  }
}
`;

const article = ({ data }) => {
  const { datoCmsArticle: { article, title, id }} = data;
  return (
    <Layout>
      <SEO title={title}/>
      <HeaderSlider articleId={id} title={title} data={data}/>
      <Article data={article}/>
    </Layout>
  );
};
export default article;
