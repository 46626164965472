import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import styles from './HeaderSlider.module.scss';
import BigArticleTitle from '../../../shared/texts/BigArticleTitle/BigArticleTitle';
import SliderArticleHeader from '../../../shared/Sliders/SliderArticleHeader/SliderArticleHeader';

const HeaderSlider = ({title, articleId, data}) => (
  <div className={cs(styles.wrap, 'wrap')}>
    <BigArticleTitle className={cs(styles.articleTitle, 'wow', 'animate__slideInUp')} data-wow-duration=".7s">{title}</BigArticleTitle>
    <SliderArticleHeader articleId={articleId} data={data}/>
  </div>
);

HeaderSlider.propTypes = {
  title: PropTypes.string.isRequired,
  articleId: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired
};

export default HeaderSlider;
